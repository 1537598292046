<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('inboundMessages') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :sort="'desc'"
    :license="license"
    :request="getInboundMessagesRequest"
    :is-search="true"
    :csv-handler="downloadCsvHandler"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table
      :list="list"
    ></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { getInboundMessages } from '@/application/services/statistic.js'
import { store } from '@/application/store'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVInboundMessages } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ScheduledCampaignsPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/InboundMessagesTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getInboundMessagesRequest = getInboundMessages

    const {
      downloadCsvHandler,
    } = useCsvExport(
      'inbound-messages.csv',
      formatterCSVInboundMessages,
      {
        recipient: i18n.t('recipient'),
        text: i18n.t('text'),
        type: i18n.t('type'),
        sandbox: i18n.t('sandbox'),
        date: i18n.t('date'),
      }
    )
    return {
      license,
      getInboundMessagesRequest,
      downloadCsvHandler,
    }
  },
}
</script>
