import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const inboundMessagesAPI = `${domain}api/v1/statistic/inbound-messages`
const scheduledCampaignsAPI = `${domain}api/v1/statistic/scheduled-campaigns`
const sentMessagesAPI = `${domain}api/v1/statistic/sent-messages`
const webhookHistoryApi = `${domain}api/v1/statistic/webhook-history`
const webhookHistoryRetryApi = (id) => `${domain}api/v1/statistic/webhook-history/retry/${id}`
const sandboxMessagesAPI = `${domain}api/v1/statistic/sandbox-requests`
const scheduledMessagesAPI = `${domain}api/v1/statistic/scheduled-messages`
const scheduledCampaignSingleAPI = `${domain}api/v1/statistic/scheduled-campaign/`
const additionalPaymentAPI = `${domain}api/v1/statistic/additional-payments`
const lookupHistoryAPI = `${domain}api/v1/statistic/lookup-history`
const lookupBulkHistoryAPI = `${domain}api/v1/statistic/lookup-list-history`

export const postWebhookHistoryRetryApi = (webhookId, headers) => interceptor(axios).post(webhookHistoryRetryApi(webhookId, { headers }))
export const getWebhookHistory = (params, headers) => interceptor(axios).get(webhookHistoryApi, { params, headers })
export const getScheduledCampaigns = (params, headers) => interceptor(axios).get(scheduledCampaignsAPI, { params, headers })
export const getSentMessages = (params, headers) => interceptor(axios).get(sentMessagesAPI, { params, headers })
export const getSandboxMessages = (params, headers) => interceptor(axios).get(sandboxMessagesAPI, { params, headers })
export const getScheduledMessages = (params, headers) => interceptor(axios).get(scheduledMessagesAPI, { params, headers })
export const getScheduledCampaignSingle = (campaignId, headers) => interceptor(axios).get(`${scheduledCampaignSingleAPI}${campaignId}`, { headers })
export const getInboundMessages = (params, headers) => interceptor(axios).get(inboundMessagesAPI, { params, headers })
export const getAdditionalPayment = (params, headers) => interceptor(axios).get(additionalPaymentAPI, { params, headers })
export const cancelBulk = (campaignId, headers) => interceptor(axios).delete(scheduledCampaignSingleAPI + campaignId, { headers })
export const getLookupHistory = (params, headers) => interceptor(axios).get(lookupHistoryAPI, { params, headers })
export const getLookupBulkHistory = (params, headers) => interceptor(axios).get(lookupBulkHistoryAPI, { params, headers })
